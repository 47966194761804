<template>
  <div id="receipt" class="receipt">
    <div class="receipt-header">
      <div class="receipt-header-company-details">
        <div class="company-name"><h1>{{ document.details.header }}</h1></div>
        <div class="separator separator-2"></div>
        <div>{{ document.details.business.company }}</div>
        <div>{{ $t("business_number") }} {{ document.details.business.company_number }}</div>
        <div>{{ document.details.branch_address }}</div>
        <div>{{ $t(DocumentType[document.document_type]) }} {{ document.document_number }}</div>
        <div v-if="linked_document">{{ $t('linked_to') }} {{ linked_document }}</div>

      </div>
      <div class="receipt-header-order-number">
        <div class="black-div"> {{ $t("order_no") }} {{ document.details.daily_order_id }}</div>
        <span v-if="document.details.order_source && document.details.order_type"> {{
            $t(OrderSource[document.details.order_source])
          }} | {{ $t(OrderType[document.details.order_type]) }} </span>
      </div>
      <div class="separator"></div>
      <div class="receipt-header-client-details">
        <div class="client-name">
          <h1> {{ document.client.name }} </h1>
          <h2> {{ document.client.phone }} </h2>
        </div>
        <div class="delivery_address">
          <div class="delivery_address-row" v-if="document.client.city">
            <span>{{ $t("city") }}</span>
            <span>{{ document.client.city }}</span>
          </div>
          <div class="delivery_address-row" v-if="document.client.street">
            <span>{{ $t("street") }}</span>
            <span>{{ document.client.street }}</span>
          </div>
          <div class="delivery_address-row" v-if="document.client.number">
            <span>{{ $t("number") }}</span>
            <span>{{ document.client.number }}</span>
          </div>
          <div class="delivery_address-row" v-if="document.client.floor">
            <span>{{ $t("floor") }}</span>
            <span>{{ document.client.floor }}</span>
          </div>
          <div class="delivery_address-row" v-if="document.client.apartment">
            <span>{{ $t("apartment") }}</span>
            <span>{{ document.client.apartment }}</span>
          </div>
        </div>
        <div class="separator"></div>
      </div>
    </div>
    <div class="receipt-body">
      <div class="receipt-items" v-if="document.items.length">
        <div class="receipt-item">
          <span>{{ $t("item") }}</span>
          <span>{{ $t("amount") }}</span>
          <span>{{ $t("price") }}</span>
        </div>
        <div class="receipt-item" v-for="item in document.items" :key="item.id">
        <span>
          <span v-for="index in item.level" :key="index" class="padding"></span>
          <span v-if="item.level">--></span>
          {{ item.title }}
        </span>
          <span>{{ item.amount ? item.amount : '' }}</span>
          <span>{{ item.price ? item.price : '' }}</span>
        </div>
        <div class="separator"></div>
      </div>
      <div class="receipt-totals" v-if="DocumentType[document.document_type] !== 'DOCUMENT_TYPE_RECEIPT'">
        <div>
          <span>{{ $t("sub-total") }}</span>
          <span>{{ (document.amount - document.details.taxTotal).toFixed(2) }}</span>
        </div>
        <div v-for="([key, value], index) in Object.entries(document.details.tax_summary)" :key="index">
          <span>{{ $t("taxable") }} {{ key }}%</span>
          <span>{{ value - (value - (value / (1 + (key / 100)))).toFixed(2) }}</span>
        </div>
        <div>
          <span>{{ $t("total-tax") }}</span>
          <span>{{ document.details.taxTotal.toFixed(2) }}</span>
        </div>
        <div class="total-row">
          <span>{{ $t("total-include-tax") }} {{ (+document.amount).toFixed(2) }} {{
              $t(document.details.payment_currency_symbol)
            }}</span>
        </div>

        <div class="separator"></div>
      </div>
      <div class="receipt-refund" v-else>
        <div class="black-div"> {{ $t("refund_label") }}</div>
        <div class="separator"></div>
      </div>
      <div class="receipt-payments" v-if="document.details.payments.length">
        <div class="receipt-payment">
          <span>{{ $t("type") }}</span>
          <span>{{ $t("receive") }}</span>
          <span>{{ $t("paid") }}</span>
          <span>{{ $t("change") }}</span>
        </div>
        <div class="receipt-payment" v-for="(payment, index) in document.details.payments" :key="index">
          <span>{{
              $t(PaymentType[document.details.actual_payment_type])
            }} ({{ $t(document.details.payment_currency_symbol) }})</span>
          <span>{{ (payment.amount + payment.change).toFixed(2) }}</span>
          <span>{{ payment.amount.toFixed(2) }}</span>
          <span>{{ payment.change.toFixed(2) }}</span>
        </div>
        <div class="separator"></div>
      </div>
    </div>
    <div class="receipt-footer">
      <div v-if="document.details.branch_order_id">
        <span>{{ $t("branch_order_id") }}</span>
        <span>{{ document.details.branch_order_id }}</span>
      </div>
      <div v-if="document.details.number_of_diners">
        <span>{{ $t("number_of_diners") }}</span>
        <span>{{ document.details.number_of_diners }}</span>
      </div>
      <div v-if="document.details.created_by">
        <span>{{ $t("created_by") }}</span>
        <span>{{ document.details.created_by }}</span>
      </div>
      <div v-if="document.details.branch">
        <span>{{ $t("branch") }}</span>
        <span>{{ document.details.branch }}</span>
      </div>
      <div v-if="document.details.order_type">
        <span>{{ $t("order_type") }}</span>
        <span>{{ $t(OrderType[document.details.order_type]) }}</span>
      </div>
      <div v-if="document.details.order_source">
        <span>{{ $t("order_source") }}</span>
        <span>{{ $t(OrderSource[document.details.order_source]) }}</span>
      </div>
      <div v-if="document.details.done_date">
        <span>{{ $t("done_date") }}</span>
        <span>{{ formatDateString(document.details.done_date) }}</span>
      </div>

    </div>

    <div class="credit">
      <span><a href="https://atmos.co.il"><img src="./../assets/atmos-logo-black.svg" alt=""></a></span>


    </div>
  </div>
</template>

<script>

import {DocumentType, PaymentType} from "@/constants/payment";
import {OrderSource, OrderType} from "@/constants/order";

export default {
  name: 'ReceiptComponent',
  computed: {
    OrderSource() {
      return OrderSource
    },
    OrderType() {
      return OrderType
    },
    PaymentType() {
      return PaymentType
    },
    DocumentType() {
      return DocumentType
    }
  },
  props: {
    document: Object,
    linked_document: String,
    required: Boolean
  },
  methods: {
    formatDateString(dateString) {
      const date = new Date(dateString);

      const day = date.getDate();
      const month = date.getMonth() + 1; // getMonth() returns 0-11
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();

      return `${day}/${month}/${year} ${hours}:${minutes.toString().padStart(2, '0')}`;
    }
  },
  data() {
    return {
      document_type: 3,
      document_number: 108486,
      companyName: 'My Company', // This will be dynamic
      client: {
        "fax": null,
        "city": "באר שבע",
        "name": "ירין",
        "email": "yarinab1@gmail.com",
        "floor": "111",
        "phone": "0503266463",
        "state": null,
        "number": 2,
        "street": "עמרי",
        "zip_code": null,
        "apartment": "222",
        "contact_name": "ירין",
        "company_number": null
      },
      actual_payment_type: 0,
      header: "פיצה האט\nאביסרור",
      business: {
        "id": 2,
        "tax": 0,
        "city": "באר שבע",
        "email": null,
        "phone": "+9721700506070",
        "state": "הדרום",
        "company": "קובה דלעת בע''מ",
        "address1": "ישראל",
        "address2": "מגדלי אביסרור שדרות רגר 53",
        "invoices": 0,
        "zip_code": "40205050",
        "last_name": "פיצה האט",
        "created_at": "2021-10-19 12:20:51",
        "first_name": "באר שבע - אביסרור",
        "updated_at": "2023-12-19 14:28:50",
        "business_type": 1,
        "company_number": "514179804",
        "deductions_file": null,
        "tax_withholding": 0,
        "accounting_email": null,
        "income_tax_deposit": 0,
        "social_security_deposit": 0,
        "tax_withholding_expiration": null
      },
      branch_address: "יצחק רגר 54 באר שבע",
      daily_order_id: 371,
      sub_total: 100,
      taxTotal: 16.65,
      received: 303.75,
      payment_currency_symbol: "ILS",
      branch_order_id: 15918,
      number_of_diners: 2,
      created_by: "מנהל סניף",
      branch: "אביסרור",
      order_type: 0,
      order_source: 1,
      done_date: "2024-01-07 08:17:55",
      payments: [
        {
          "date": "2024-01-07",
          "amount": 303.75,
          "change": 0,
          "payment_method": 2
        }
      ],
      tax_summary: {
        "0": 217.8,
        "16": 0.95,
        "16.253": 85
      },
      items: [
        {
          "net": 0,
          "tax": 0,
          "level": 0,
          "makat": 394,
          "price": 0,
          "title": "דיל בדיקה מחיר משלוח/איסוף",
          "total": 0,
          "amount": 1
        },
        {
          "net": 10,
          "tax": 0,
          "level": 1,
          "makat": 3434,
          "price": 10,
          "title": "קוקה קולה ליטר וחצי",
          "total": 10,
          "amount": 1
        },
        {
          "net": 27.26,
          "tax": 17,
          "level": 1,
          "makat": 1827,
          "price": 31.9,
          "title": "לחם שום משפחתי",
          "total": 31.9,
          "amount": 1
        },
        {
          "net": 73.12,
          "tax": 16.253,
          "level": 0,
          "makat": 11008,
          "price": 85,
          "title": "משפחתית + תוספת + שתיה 85",
          "total": 85,
          "amount": 1
        },
        {
          "net": 0,
          "tax": 0,
          "level": 1,
          "makat": 24,
          "price": 0,
          "title": "פיצה דקה L",
          "total": 0,
          "amount": 1
        },
        {
          "net": 0,
          "tax": 0,
          "level": 2,
          "makat": 5248,
          "price": 0,
          "title": "ת. סלט עגבניות שרי L חצי 1",
          "total": 0,
          "amount": 0.5
        },
        {
          "net": 0,
          "tax": 0,
          "level": 2,
          "makat": 1373,
          "price": 0,
          "title": "קטשופ חצי 1",
          "total": 0,
          "amount": 0.5
        },
        {
          "net": 0,
          "tax": 0,
          "level": 2,
          "makat": 878,
          "price": 0,
          "title": "רוטב שום חצי 2",
          "total": 0,
          "amount": 0.5
        },
        {
          "net": 0,
          "tax": 0,
          "level": 2,
          "makat": 372,
          "price": 0,
          "title": "בצל סגול כל הפיצה",
          "total": 0,
          "amount": 1
        },
        {
          "net": 0,
          "tax": 0,
          "level": 2,
          "makat": 8901,
          "price": 0,
          "title": "רוטב פיצה שום כל הפיצה",
          "total": 0,
          "amount": 1
        },
        {
          "net": 0,
          "tax": 0,
          "level": 1,
          "makat": 87,
          "price": 0,
          "title": "דיאט קולה ליטר וחצי",
          "total": 0,
          "amount": 1
        },
        {
          "net": 100,
          "tax": 0,
          "level": 0,
          "makat": 503,
          "price": 100,
          "title": "סלט כורדי",
          "total": 100,
          "amount": 1
        },
        {
          "net": 0,
          "tax": 0,
          "level": 1,
          "makat": 8901,
          "price": 0,
          "title": "רוטב פיצה שום ",
          "total": 0,
          "amount": 1
        },
        {
          "net": 0,
          "tax": 0,
          "level": 1,
          "makat": 253,
          "price": 0,
          "title": "ללא גבינת מוצרלה XL ",
          "total": 0,
          "amount": 1
        },
        {
          "net": 0,
          "tax": 0,
          "level": 1,
          "makat": 5239,
          "price": 0,
          "title": "ת. סלט גזר L ",
          "total": 0,
          "amount": 1
        },
        {
          "net": 0,
          "tax": 0,
          "level": 1,
          "makat": 8901,
          "price": 0,
          "title": "רוטב פיצה שום ",
          "total": 0,
          "amount": 1
        },
        {
          "net": 0,
          "tax": 0,
          "level": 1,
          "makat": 5254,
          "price": 0,
          "title": "ת.סלט פלפל אדום L ",
          "total": 0,
          "amount": 1
        },
        {
          "net": 0.82,
          "tax": 16,
          "level": 0,
          "makat": 530,
          "price": 0.95,
          "title": "שקל בלבד",
          "total": 0.95,
          "amount": 1
        },
        {
          "net": 74.9,
          "tax": 0,
          "level": 0,
          "makat": 24,
          "price": 74.9,
          "title": "פיצה דקה L",
          "total": 74.9,
          "amount": 1
        },
        {
          "net": 1,
          "tax": 0,
          "level": 1,
          "makat": 877,
          "price": 1,
          "title": "רוטב אלף האיים כל הפיצה",
          "total": 1,
          "amount": 1
        }
      ]
      // ... other data properties
    };
  },
}

</script>

<style scoped>
.receipt {
  position: relative;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  font-size: 14px;
}

[dir="rtl"] .receipt {
  text-align: right;
}

[dir="ltr"] .receipt {
  text-align: left;
}

.separator {
  border-top: 1px dashed #000;
  margin: 16px 0;
}

.separator-2 {
  border-width: 2px;
}

.black-div {
  text-align: center;
  background: black;
  color: white;
  display: block;
  font-size: 2em;
  font-weight: bold;
}

.receipt-header {
}

.receipt-header-company-details {
  text-align: center;
  margin-bottom: 8px;
}

.company-name {
  text-align: center;
  white-space: pre-line;
}

.receipt-header-order-number {
  text-align: center;
}

.receipt-header-client-details {
}

.client-name {
  text-align: center;
  margin-bottom: 8px;
}

.client-name h1, .client-name h2 {
  margin: 0;
}

.client-name h1 {
  font-size: 2em;
}

.client-name h2 {
  font-size: 1.6em;
}

.delivery_address {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 10px;
}

.delivery_address-row {
  display: flex;
  flex-direction: column;
  flex: 0 0 33%;
}

.receipt-body .receipt-items .receipt-item {
  display: flex;
  justify-content: space-between;
  padding-top: 3px;
  padding-bottom: 2px;
}

.receipt-item .padding {
  padding: 0 7px;
}

.receipt-item span:nth-child(1) {
  flex: 1;
}

.receipt-item span:nth-child(2),
.receipt-item span:nth-child(3) {
  width: 50px;
}

.receipt-totals div, .receipt-payments .receipt-payment {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.total-row, .receipt-totals .total-row {
  font-weight: bold;
  text-align: center;
  justify-content: center;
  min-width: 100%;
  font-size: 1.4em;
}

.receipt-footer div {
  display: flex;
  justify-content: space-between;
  padding: 1px 0;
}

.receipt-footer span {
  flex: 0.5;
}

.credit {
  text-align: center;
  direction: ltr;
  font-size: 2em;
  margin-top: 30px;
}

.credit img {
  width: 100px;
}
</style>
