export const OrderSource = [
    "website",
    "pos",
    "kiosk",
    "application",
    "phone",
    "external"
];

export const OrderType = [
    "delivery",
    "pick_up",
    "sitting",
    "dining",
    'drive_through',
    'takeaway'
];