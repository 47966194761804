export const PaymentType = [
    "CASH",
    "CREDIT",
    "POINT",
    "CREDIT_EMV",
    "COMPENSATION",
    "ADMIN_DISCOUNT",
    "MULTI_PASS",
    "BUSINESS_CLIENT",
    "CIBUS",
    "TENBIS",
    "BIT",
    "GOODI",
    "CREDIT_INPUT",
    "HAAT_DELIVERY",
    "WOLT",
    "MISHLOHA",
    "CHEQUE",
    "BANK_TRANSFER",
    "GIFT_CARD",
    "SAFECASH",
    "BUSINESSLOGIC",
    "MISCELLANEOUS"
];

export const DocumentType = [
    "",
    "DOCUMENT_TYPE_TAX_INVOICE",
    "DOCUMENT_TYPE_RECEIPT",
    "DOCUMENT_TYPE_TAX_INVOICE_RECEIPT",
    "DOCUMENT_TYPE_REFUND_INVOICE",
    "DOCUMENT_TYPE_PROFORMA_INVOICE",
    "DOCUMENT_TYPE_DELIVERY_DOCUMENT"
]

