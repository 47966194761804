<template>
  <!-- Loader -->
  <div v-if="loading" class="loading">
    <img src="./assets/atmos-logo-black.svg" alt="">
    <div class="spinner"></div>
  </div>

  <!-- Documents -->
  <div id="atmosReceipts" v-else-if="documents" >
    <div id="logo">
      <img :src="logo" alt="restaurant_logo">
    </div>
    <div v-for="document in documents" :key="document.id" :dir="direction">
      <div id="atmosReceipt">

        <button id="downloadPDF" @click="downloadPdf(document.id)">
          <img src="./assets/download-file.svg" alt="">
        </button>
        <atmosReceipt :document="document" :style="'direction:' + direction" :class="'receipt_id_' + document.id" />
      </div>
      <div id="atmosReceipt" v-for="linked_doc in document.linked_documents" :key="linked_doc.id" :dir="direction">
        <button id="downloadPDF" @click="downloadPdf">
          <img src="./assets/download-file.svg" alt="">
        </button>
        <atmosReceipt :document="linked_doc" :linked_document="document.document_number" :style="'direction:' + direction" :class="'receipt_id_' + document.id" />
      </div>
    </div>
  </div>

  <!-- Error Message -->
  <div v-else-if="errorMessage">
    {{ errorMessage }}
  </div>

</template>

<script>
import atmosReceipt from "@/components/atmosReceipt.vue";
import axios from "axios";
import html2pdf from "html2pdf.js";

export default {
  name: 'App',
  components: {
    atmosReceipt
  },
  computed: {
    tenant_details() {
      return this.$tenant_details;
    },
    direction() {
      return this.$current_lang === 'he' ? 'rtl' : 'ltr';
    },
    order_id() {
      return this.$order_id;
    }
  },
  data() {
    return {
      loading: false,
      documents: null,
      errorMessage: ''
    };
  },
  mounted() {
    this.fetchDocuments();
  },
  methods: {
    downloadPdf(id) {
      const doc = this.documents.find(d => d.id === id);
      const element = document.getElementsByClassName('receipt_id_' + id)[0];
      const pageHeight = element.clientHeight + 100;
      console.log(pageHeight)
      const pageWidth = 380;
      html2pdf(element, {
        margin: 10,
        filename: 'atmos_receipt_' + doc.document_number + '.pdf',
        image: {type: 'jpeg', quality: 0.98},
        html2canvas: {dpi: 192, letterRendering: false},
        jsPDF: {unit: 'px', format: [pageWidth, pageHeight], orientation: 'p'}
      });
    },
    async fetchDocuments() {
      this.loading = true;
      this.errorMessage = '';
      this.documents = null;
      this.logo = null;

      const formData = new FormData();
      formData.append('restaurant_id', this.tenant_details.restaurant_id);
      formData.append('order_id', this.order_id);

      const url = `${this.$tenant_details.api_url}/receipt/get`;
      const startTime = Date.now(); // Record the start time

      try {
        const response = await axios.post(url, formData);
        this.documents = response.data.result.documents;

        this.documents.forEach(document => {
          document.linked_documents = document.linked_documents ?? [];
          if (document.linked_id) {
            const linkedDocument = this.documents.find(d => d.id === document.linked_id);
            if (linkedDocument) {
              linkedDocument.linked_documents = [...(linkedDocument.linked_documents ?? []), document];
            }
          }
        });
        this.documents = this.documents.filter(document => !document.linked_id);

        this.logo = `https://files.geteat.co.il/atmos/${this.tenant_details.restaurant_id}/images/` + response.data.result.logo;

      } catch (error) {
        this.errorMessage = error?.response?.data?.result?.errorMessage || 'An error occurred';
      } finally {
        const elapsedTime = Date.now() - startTime;
        const remainingTime = Math.max(1000 - elapsedTime, 0);
        await new Promise(resolve => setTimeout(resolve, remainingTime));
        this.loading = false;
      }

    }
  }
}

</script>

<style>
/* Global Styles */
body {
  margin: 0;
  height: 100vh;
  background: #1f242c;
  font-family: 'Ploni', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
}

/* Loading Container Styles */
.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
}

.loading img {
  width: 200px;
}

#downloadPDF {
  position: absolute;
  top: 25px;
  left: 25px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1;
}

#downloadPDF img {
  width: 30px;
}

/* Spinner Styles */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Atmos Receipt Styles */
#atmosReceipts {
  width: 380px;
}

#atmosReceipt {
  width: 350px;
  padding: 15px;
  position: relative;
}

#logo {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}

#logo img {
  width: 60vw;
}
/* Font Face Definitions */

@font-face {
  font-family: 'Ploni';
  src: url('./assets/fonts/ploni-light.woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Ploni';
  src: url('./assets/fonts/ploni-regular.woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Ploni';
  src: url('./assets/fonts/ploni-medium.woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Ploni';
  src: url('./assets/fonts/ploni-bold.woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Ploni';
  src: url('./assets/fonts/ploni-black.woff');
  font-weight: 900;
  font-style: normal;
}

</style>
