import { createApp } from 'vue';
import App from './App.vue';
import { getApi } from "@/getApi";
import { createI18n } from 'vue-i18n';

const path = window.location.pathname;
const urlSegments = path.split("/").filter(p => p);
const urlRestId = urlSegments[0]; // Assuming the first segment is the restaurant ID
const order_id = urlSegments[1]; // Assuming the second segment is the order ID

const tenant_details = await getApi(urlRestId);
const language = tenant_details.default_lang ?? 'he';

// Load the translation messages
const messages = {
    en: require('./translations/en.json'),
    he: require('./translations/he.json')
    // Add other languages here
};

// Create the vue-i18n instance
const i18n = createI18n({
    legacy: false, // for Vue 3 you need to set this to false
    locale: language, // set default locale
    fallbackLocale: 'he', // set fallback locale
    messages, // set locale messages
});

// Create the Vue app
const app = createApp(App);
app.config.globalProperties.$tenant_details = tenant_details;
app.config.globalProperties.$current_lang = language;
app.config.globalProperties.$order_id = order_id;

// Use the vue-i18n plugin
app.use(i18n);

// Now mount the app
app.mount('#app');
